

import * as hBars from 'handlebars/dist/handlebars';
import throttle from 'lodash-es/throttle';
import { sanitize } from './_sanitize.js';
import { getSearchHistory } from '../inc/search-history.js';
import { setSearchHistory } from '../inc/search-history.js';
import { getSearchHistoryHTML } from '../inc/search-history.js';

if ($('body').hasClass('wattseu')) {

    const $searchBar = $('.js-search-bar-eu');
    const $searchDestination = ($('.js-search-bar-eu__form').length) ? $('.js-search-bar-eu__form').attr('action') : "/search";
    const $searchHistoryStorageItemName = ($('body').attr('data-website') + "-" + $('.header-serve-history').attr('data-header-name')).replace(/\s+/g, '-').toLowerCase();
    var $ajaxWrapper = null;
    var $ajaxhtmlSource = null;
    var $startCountFromPageload = 0;


    if ($('.eu-search-reults-wrapper').length) {
        $ajaxWrapper = $('.eu-search-reults-wrapper');
    }

    function GetqhParam() {
        var param = 'qh';
        var retValue = "";
        var url = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for (var i = 0; i < url.length; i++) {
            var urlparam = url[i].split('=');
            if (urlparam[0] == param) {
                return urlparam[1];
            }
        }
        return retValue;
    }

    function GetPageParam() {
        var param = 'page';
        var retValue = "";
        var url = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for (var i = 0; i < url.length; i++) {
            var urlparam = url[i].split('=');
            if (urlparam[0] == param) {
                return urlparam[1];
            }
        }
        return retValue;
    }

    function HightlightSearchTermInProductDetailPage(searchword) {
        if (searchword != "") {
            $('.main-container').highlight(searchword.toLowerCase());
        }
    }

    function HightlightSearchTermInSearchPage(searchword) {
        if (searchword != "") {
            $('.divsearchresultspage.eu-products-container').highlight(searchword.toLowerCase());
        }
    }

    function HightlightSearchTermInProductsPage(searchword) {
        if (searchword != "") {
            $('.eu-products-container').highlight(searchword.toLowerCase());
            $('h1.page-title').highlight(searchword.toLowerCase());
        }
    }

    $(document).on("click", ".eu-show-all-results", function (e) {
        $('#searchButton').click();
    });

    $(document).on("click", ".js-new-search-btn", function (e) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        $('.header-search').click();
    });

    function getCurrentPageFromURL() {
        var urlParams = new URLSearchParams(window.location.search);
        return parseInt(urlParams.get('page')) || 1;
    }

    var currentPage = getCurrentPageFromURL();

    function generateSearchPageNumbers(currentPage, pageTotal) {
        var pageNumbers = '';
        var startPage = currentPage - 1 > 0 ? currentPage - 1 : 1;
        var endPage = startPage + 2 <= pageTotal ? startPage + 2 : pageTotal;

        if ($(window).width() >= 577) {
            for (var i = 1; i <= pageTotal; i++) {
                var numClass = "pagenum" + i.toString();
                pageNumbers += '<span class="page-number ' + numClass + '">' + i + '</span>';
            }
        }
        //Mobile Pagination Numbers
        if ($(window).width() <= 576) {
            for (var i = startPage; i <= endPage; i++) {
                var numClass = "pagenum" + i.toString();
                pageNumbers += '<span class="page-number ' + numClass + '">' + i + '</span>';
            }
        }


    if ($(window).width() <= 576) {
        for (var i = startPage; i <= endPage; i++) {
            var numClass = "pagenum" + i.toString();
            pageNumbers += '<span class="page-number ' + numClass + '">' + i + '</span>';
        }
    }


    $('.eu-search-reults-wrapper .pagination-container .page-numbers').html(pageNumbers);
    $('.eu-search-reults-wrapper .pagination-container .page-number').on('click', function () {
        if (!$(this).hasClass('active-page-num')) {
            var pageNumber = parseInt($(this).text());
            if (!isNaN(pageNumber)) {
                var searchterm = encodeURIComponent($('.eu-search-reults-wrapper').attr('data-searchterm'));
                window.location.href = $searchDestination + "?q=" + searchterm + "&page=" + pageNumber;
            }
        }
    });


    }

    function LoadPage(status) {
        const $sWrapper = $('.eu-search-reults-wrapper');
        const $sPageSize = $sWrapper.attr("data-items-perpage");
        var pageFromURL = GetPageParam();
        var curpage = parseInt("1");
        if (pageFromURL != null && pageFromURL != "") {
            curpage = parseInt(pageFromURL);
        }
        var totalItemcount = parseInt($('#resultCount').text());
        var totalPageCount = Math.ceil(totalItemcount / $sPageSize);
        var searchterm = encodeURIComponent($('.eu-search-reults-wrapper').attr('data-searchterm'));

        if (status === 'next') {
            if (curpage < totalPageCount) {
                window.location.href = $searchDestination + "?q=" + searchterm + "&page=" + (curpage + 1).toString();
            }
        }
        else if (status === 'previous') {
            if (curpage !== 1 && curpage > 1) {
                window.location.href = $searchDestination + "?q=" + searchterm + "&page=" + (curpage - 1).toString();
            }
        }
    }
    function StartSearchPagination(totalresults) {
        var numItems = totalresults;
        var container = $('.eu-search-reults-wrapper .pagination-container');
        var perPage = parseInt(container.attr('data-perpageitem'));

        var totalPages = Math.ceil(numItems / perPage);

        $('.eu-search-reults-wrapper .pagination-container .prev').hide();

        generateSearchPageNumbers(currentPage, totalPages);
        $('.eu-search-reults-wrapper .products-more').css("display", "block");
        $('.eu-search-reults-wrapper .pagination-container').css("display", "flex");

        $('.eu-search-reults-wrapper .pagination-container .prev').on('click', function () {
            if ($(window).width() <= 567) {
                if (currentPage > 1) {
                    currentPage--;
                    generateSearchPageNumbers(currentPage, totalPages);
                }
            }
            LoadPage('previous');
        });


        $('.eu-search-reults-wrapper .pagination-container .next').on('click', function () {
            if ($(window).width() <= 567) {
                if (currentPage < totalPages) {
                    currentPage++;
                    generateSearchPageNumbers(currentPage, totalPages);
                }
            }
            LoadPage('next');
        });

        $('.eu-search-reults-wrapper .products-more').on('click', function () {
            //LoadPage('next');
            SearchLoadMoreAjaxFill();
        });
    }

    function SetEnablementOfPaginationControls() {
        var paginationText = $("#pagination-count").text();
        const $sWrapper = $('.eu-search-reults-wrapper');
        const $sPageSize = $sWrapper.attr("data-items-perpage");
        var productsText = $sWrapper.attr("data-product-text");
        var pageFromURL = GetPageParam();
        var curpage = parseInt("1");
        if (pageFromURL != null && pageFromURL != "") {
            curpage = parseInt(pageFromURL);
        }
        var totalItemcount = parseInt($('#resultCount').text());

        var totalPageCount = Math.ceil(totalItemcount / $sPageSize);

        if (curpage === totalPageCount) {
            $('.eu-search-reults-wrapper .products-more').hide();
            $('.eu-search-reults-wrapper .pagination-container .next').hide();
            $('.eu-search-reults-wrapper .pagination-container .prev').show();
        }
        else if (curpage === 1) {
            $('.eu-search-reults-wrapper .products-more').show();
            $('.eu-search-reults-wrapper .pagination-container .next').show();
            $('.eu-search-reults-wrapper .pagination-container .prev').hide();
        }
        else if (curpage < totalPageCount) {
            $('.eu-search-reults-wrapper .products-more').show();
            $('.eu-search-reults-wrapper .pagination-container .next').show();
            $('.eu-search-reults-wrapper .pagination-container .prev').show();
        }

        /*Add active class*/
        $('.eu-search-reults-wrapper .pagination-container .page-number').removeClass('active-page-num');
        $('.eu-search-reults-wrapper .pagination-container .' + 'pagenum' + curpage.toString()).addClass('active-page-num');

        /*Update the records count at bottom*/
        var showFrom = $sPageSize * (curpage - 1) + 1;
        var showTo = Math.min((curpage * $sPageSize), totalItemcount);
        $startCountFromPageload = showFrom;
        var updatedPaginationText = paginationText;
        updatedPaginationText = updatedPaginationText.replace('{0}', showFrom).replace('{1}', showTo);
        $('#pagination-count').text(updatedPaginationText);
        //  $('.eu-search-reults-wrapper .pagination-container .paginate-count').text(showFrom + ' - ' + showTo + ' of ' + totalItemcount + ' ' + productsText);
    }

    function SetEnablementOfPaginationControlsForAjaxCall() {
        const $sWrapper = $('.eu-search-reults-wrapper');
        const $sPageSize = $sWrapper.attr("data-items-perpage");
        var productsText = $sWrapper.attr("data-product-text");
        var pageFromURL = GetPageParam();
        var curpage = parseInt("1");
        var d = $sWrapper.attr("data-current-page");
        if (d != null && d != "") {
            curpage = (parseInt(d) + 1);
        }
        var totalItemcount = parseInt($('#resultCount').text());
        var totalPageCount = Math.ceil(totalItemcount / $sPageSize);

        if (curpage === totalPageCount) {
            $('.eu-search-reults-wrapper .products-more').hide();
        }
        else if (curpage === 1) {
            $('.eu-search-reults-wrapper .products-more').show();
        }
        else if (curpage < totalPageCount) {
            $('.eu-search-reults-wrapper .products-more').show();
        }

        /*Update the records count at bottom*/
        var showFrom = $sPageSize * (curpage - 1) + 1;
        var showTo = Math.min((curpage * $sPageSize), totalItemcount);

        $('.eu-search-reults-wrapper .pagination-container .paginate-count').text($startCountFromPageload + ' - ' + showTo + ' of ' + totalItemcount + ' ' + productsText);
    }

    /*Search results Page - Landing functionality*/
    $(document).ready(function () {
        if ($('#diveusearchresults').length) {
            const $sWrapper = $(this).find('.eu-search-reults-wrapper');
            const $spath = $sWrapper.attr('data-endpoint');
            const $sterm = sanitize($sWrapper.attr('data-searchterm'));
            const $htmlSource = $sWrapper.find(".results-search__template").html();
            const $starget = $sWrapper.children(".eu-final-results");
            const $sPageSize = $sWrapper.attr("data-items-perpage");
            var $sCurrentPage = parseInt("1");
            var d = GetPageParam();
            if (d != null && d != "") {
                $sCurrentPage = parseInt(d);
            }
            $sCurrentPage = (parseInt($sCurrentPage) - 1);
            $('.eu-search-reults-wrapper').attr("data-current-page", $sCurrentPage);

            const requestData = { Page: $sCurrentPage, PageSize: $sPageSize, SearchTerm: $sterm };

            $.ajax({
                type: "GET",
                data: requestData,
                url: $spath,
                async: true,
                beforeSend: function () {
                    if (!$sWrapper.find('.loader-wrapper').length) {
                        $sWrapper.append('<div class="loader-wrapper"><div class="loader"></div></div>');
                    } else {
                        $sWrapper.find('.loader-wrapper').fadeIn('fast');
                    }
                },
            }).done(function (data) {

                if (data != null && data.Results != null && data.Results.length > 0) {

                    const suggestion = data;
                    const template = hBars.compile($htmlSource);
                    if (suggestion) {
                        const suggested = template(suggestion);
                        $starget.html(suggested);

                        $('#resultCount').text(data.TotalResults);
                        $('#productCount').text(data.TotalResults);
                        $('#spansearchterm').text($sterm);
                    }

                    /*Highlight searchterm in search results page*/
                    HightlightSearchTermInSearchPage($sterm);

                    /*Pagination Control*/
                    var totalcount = parseInt($('#resultCount').text());
                    if (totalcount > $sPageSize) {
                        $('.eu-search-reults-wrapper .pagination-container').css('display', 'flex');
                        $('.eu-search-reults-wrapper .products-more').show();
                        StartSearchPagination(totalcount);
                        SetEnablementOfPaginationControls();
                    }
                    else {
                        $('.eu-search-reults-wrapper .pagination-container').css('display', 'none');
                        $('.eu-search-reults-wrapper .products-more').hide();
                    }

                    $sWrapper.find('.loader-wrapper').fadeOut('slow');

                    /*Adding into searchhistory*/
                    setSearchHistory($sterm, $searchDestination, $searchHistoryStorageItemName, "q");
                    
                    if ($('.product-list .js-result-search__link').length) {
                        $('.product-list .js-result-search__link').each(function () {
                            var secondImageRes = $(this).find('.grid-item__media img:nth-child(2)');
                            if (secondImageRes.length) {
                                $(this).hover(
                                    function () {
                                        // Hover in
                                        $(this).find('.grid-item__media img:nth-child(1)').hide();
                                        secondImageRes.show();
                                    },
                                    function () {
                                        // Hover out
                                        $(this).find('.grid-item__media img:nth-child(1)').show();
                                        secondImageRes.hide();
                                    }
                                );
                            }
                        });
                    }
                }
                else {
                    $('.eu-search-reults-wrapper').css("display", "none");
                    $('.div-zero-reults').css("display", "block");

                    $('#resultCount').text("0");
                    $('#productCount').text("0");
                    $('#spansearchterm').text($sterm);

                    $sWrapper.find('.loader-wrapper').fadeOut('slow');

                    /*Adding into searchhistory*/
                    setSearchHistory($sterm, $searchDestination, $searchHistoryStorageItemName, "q");
                }

            }).fail(function () {

            });
        }

        var qhparam = GetqhParam();
        if (qhparam.length > 0) {
            if ($('.eu-products-container').length) {
                HightlightSearchTermInProductsPage(qhparam);
            }
            if ($('.main-container').length) {
                HightlightSearchTermInProductDetailPage(qhparam);
            }
        }
    });

    function SearchLoadMoreAjaxFill() {

        if ($('#diveusearchresults').length) {
            const $sWrapper = $('.eu-search-reults-wrapper');
            const $spath = $sWrapper.attr('data-endpoint');
            const $sterm = sanitize($sWrapper.attr('data-searchterm'));
            const $htmlSource = $(".just-results-search__template").html();
            const $starget = $sWrapper.children(".eu-final-results");
            const $sPageSize = $sWrapper.attr("data-items-perpage");
            var $sCurrentPage = parseInt("1");
            var d = $sWrapper.attr("data-current-page");
            if (d != null && d != "") {
                $sCurrentPage = (parseInt(d) + 1);
            }

            $('.eu-search-reults-wrapper').attr("data-current-page", $sCurrentPage);
            //$sCurrentPage = (parseInt($sCurrentPage) - 1);
            const requestData = { Page: $sCurrentPage, PageSize: $sPageSize, SearchTerm: $sterm };

            $.ajax({
                type: "GET",
                data: requestData,
                url: $spath,
                async: true,
                beforeSend: function () {
                    if (!$sWrapper.find('.loader-wrapper').length) {
                        $sWrapper.append('<div class="loader-wrapper"><div class="loader"></div></div>');
                    } else {
                        $sWrapper.find('.loader-wrapper').fadeIn('fast');
                    }
                },
            }).done(function (data) {

                if (data != null && data.Results != null && data.Results.length > 0) {

                    const suggestion = data;
                    const template = hBars.compile($htmlSource);
                    if (suggestion) {
                        const suggested = template(suggestion);
                        $('.product-list.unfiltered-grid').append(suggested);

                        $('#resultCount').text(data.TotalResults);
                        $('#productCount').text(data.TotalResults);
                        $('#spansearchterm').text($sterm);
                    }

                    /*Highlight searchterm in search results page*/
                    HightlightSearchTermInSearchPage($sterm);

                    /*Pagination Control*/
                    var totalcount = parseInt($('#resultCount').text());
                    if (totalcount > $sPageSize) {
                        $('.eu-search-reults-wrapper .pagination-container').css('display', 'flex');
                        $('.eu-search-reults-wrapper .products-more').show();
                        //StartSearchPagination(totalcount, parseInt($sCurrentPage));
                        //SetEnablementOfPaginationControls();
                    }
                    else {
                        $('.eu-search-reults-wrapper .pagination-container').css('display', 'none');
                        $('.eu-search-reults-wrapper .products-more').hide();
                    }

                    $sWrapper.find('.loader-wrapper').fadeOut('slow');

                    /*Adding into searchhistory*/
                    setSearchHistory($sterm, $searchDestination, $searchHistoryStorageItemName, "q");

                    if ($('.product-list .js-result-search__link').length) {
                        $('.product-list .js-result-search__link').each(function () {
                            var secondImageRes = $(this).find('.grid-item__media img:nth-child(2)');
                            if (secondImageRes.length) {
                                $(this).hover(
                                    function () {
                                        // Hover in
                                        $(this).find('.grid-item__media img:nth-child(1)').hide();
                                        secondImageRes.show();
                                    },
                                    function () {
                                        // Hover out
                                        $(this).find('.grid-item__media img:nth-child(1)').show();
                                        secondImageRes.hide();
                                    }
                                );
                            }
                        });
                    }
                }
                else {
                    $('.eu-search-reults-wrapper').css("display", "none");
                    $('.div-zero-reults').css("display", "block");

                    $('#resultCount').text("0");
                    $('#productCount').text("0");
                    $('#spansearchterm').text($sterm);

                    $sWrapper.find('.loader-wrapper').fadeOut('slow');

                    /*Adding into searchhistory*/
                    setSearchHistory($sterm, $searchDestination, $searchHistoryStorageItemName, "q");
                }

                SetEnablementOfPaginationControlsForAjaxCall();

            }).fail(function () {

            });
        }

        var qhparam = GetqhParam();
        if (qhparam.length > 0) {
            if ($('.eu-products-container').length) {
                HightlightSearchTermInProductsPage(qhparam);
            }
            if ($('.main-container').length) {
                HightlightSearchTermInProductDetailPage(qhparam);
            }
        }

    }

    $searchBar.each(function (index, value) {

        const $searchInput = $(value).find('.js-search-bar-eu__input');
        const $suggestWrapper = $(value).find('.js-predictive-search-eu__wrapper');
        const $searchButton = $(value).parent('.header__container').find('.js-search-eu-button');
        const $searchButtonLink = $searchButton.find('.js-search-button-eu__link');
        const $suggestTarget = $suggestWrapper.children('.js-predictive-search-eu__target');

        /* Get suggest results------------------------------------------------------*/
        const getSuggestions = throttle(function (typing) {
            const $suggestPath = $suggestWrapper.attr('data-endpoint');
            const $htmlSource = $suggestWrapper.find(".predictive-search__template").html();
            function updateProductCount(count, text) {
                $("#productCount").text(text + count);
                $(".tabTitleCount").text(count);
            }

            const requestData = { PageSize: '999' };
            requestData.SearchTerm = sanitize(typing);

            $.ajax({
                type: "GET",
                data: requestData,
                url: $suggestPath,
                async: true

            }).done(function (data) {
                if (data != null && data.Results != null && data.Results.length > 0) {
                    console.log("data", data.Results);

                    const suggestion = data;
                    const template = hBars.compile($htmlSource);
                    if (suggestion) {
                        const suggested = template(suggestion);
                        $suggestTarget.html(suggested);
                        var currentUrl = new URL(window.location.href);
                        var currentQuery = currentUrl.searchParams.get("q");

                        /*highlighter in left side of the predictive search*/
                        if (typing != "") {
                            $('.divsearchcategories').highlight(typing.toLowerCase());
                        }

                        $(".show-result").attr("href", $searchDestination + "/?q=" + encodeURIComponent(typing));

                        $('.tabTitleCount').text(data.SeriesCount);

                        if ($(value).hasClass('is-open')) {
                            $suggestWrapper.addClass('is-visible');
                        }

                        if ($('#searchModal').height() > '350') {
                            $('#searchModal').addClass('add-scroll');
                        } else {
                            $('#searchModal').removeClass('add-scroll');
                        }
                    }
                }
                else {
                    const suggestion = "";
                    const template = hBars.compile($htmlSource);
                    const suggested = template(suggestion);
                    $suggestTarget.html(suggested);
                    $('.tabTitleCount').text("0");
                }
                setSearchHistoryAndAddToPredictiveSearch(encodeURIComponent(typing));

                if ($('.product-list .js-predictive-search__link').length) {
                    $('.product-list .js-predictive-search__link').each(function () {
                        var secondImageMW = $(this).find('.img-container img:nth-child(2)');
                        if (secondImageMW.length) {
                            $(this).hover(
                                function () {
                                    // Hover in
                                    $(this).find('.img-container img:nth-child(1)').hide();
                                    secondImageMW.show();
                                },
                                function () {
                                    // Hover out
                                    $(this).find('.img-container img:nth-child(1)').show();
                                    secondImageMW.hide();
                                }
                            );
                        }
                    });
                }
            }).fail(function () {
                $suggestWrapper.removeClass('is-visible');
            });
        }, 1000);


        /* Make the call on keyup---------------------------------------------------*/
        $searchInput.keyup(function (event) {

            const newTyping = $(event.currentTarget).val();
            const strLen = newTyping.length;

            /* ----------------------------------------------------(Submit on Enter)*/
            if (event.which === 13 || event.keyCode === 13) {

                $(value).children('.js-search-bar-eu__form').submit();
                localStorage.setItem("searchValue", newTyping);

                /* ------------------------------------------------------(any other key)*/
            } else if (strLen > 2) {
                $('.search-history').hide();
                $('.js-predictive-search-eu__target').show();
                getSuggestions(sanitize(newTyping));

            } else {
                $('.search-history').show();
                $('.js-predictive-search-eu__target').hide();
                $suggestWrapper.removeClass('is-visible');
            }
        });// On keyUp


        /* Hiding the auto-suggestions if the user clicks elsewher on the page------*/
        $(document).mouseup(function (e) {
            const $searchWrapperChildren = $suggestWrapper.children();
            if (
                !$suggestWrapper.is(e.target)
                && $searchWrapperChildren.has(e.target).length === 0
                && !$searchButton.is(e.target)
                && !$searchButtonLink.is(e.target)
                && !$searchInput.is(e.target)
            ) {
                $suggestWrapper.removeClass('is-visible');
                $(value).removeClass('is-open');

                if (!$('.js-search-bar-eu').hasClass('is-open')) {
                    $searchButtonLink.removeClass('is-open');
                }
            }
        });
        function renderResults(searchQuery) {

            const productCount = getProductCount(searchQuery);
            updateProductCount(productCount, "Results: ");
        }
        function getProductCount(searchQuery) {

            const dummyProductCount = 10;
            return dummyProductCount;
        }
        $(document).ready(function () {
            $("form.js-search-bar-eu__form").on("submit", function (event) {
                event.preventDefault();
                search();
            });

            $(document).on("click", ".eu-ps-arrow-cta", function (event) {
                event.preventDefault();
                console.log("Clicked on All Results link");
                var searchTerm = $("#searchtermeu").val();
                if (searchTerm) {
                    var searchUrl = $searchDestination + "/?q=" + encodeURIComponent(searchTerm);
                    window.location.href = searchUrl;
                }
            });


            $("#searchHistoryContainer a").on("click", function (event) {
                var searchTerm = $(this).text();
                setSearchHistoryAndRedirect(searchTerm);
                return false;
            });



            renderSearchHistory();
        });
        
        function setSearchHistoryAndRedirect(searchTerm) {
            setSearchHistory(searchTerm, $searchDestination, $searchHistoryStorageItemName, "q");
            getSuggestions(searchTerm);

            $("#searchResultsList").html('');

            setTimeout(function () {
                var redirectUrl = $searchDestination + "/?q=" + encodeURIComponent(searchTerm).replace(/%20/g, '+');
                window.location.href = redirectUrl;
            }, 100);
        }


        function setSearchHistoryAndAddToPredictiveSearch(searchTerm) {

            $('.search-history').hide();

            var searchHistoryHTML = getSearchHistoryHTML(searchTerm, false, $searchDestination, $searchHistoryStorageItemName, "q", false, "");

            var pssearchHistoryContainer = $("#psSearchHistory");
            if (searchHistoryHTML != null && searchHistoryHTML.length) {
                pssearchHistoryContainer.html(searchHistoryHTML);

                $(".ps-search-historys .remove").on("click", function () {
                    var index = $(this).data("index");
                    $(this).parent().remove();
                    removeSearchHistoryForProductSearch(index);
                });
            }
        }
        
        function search() {
            var typing = $("#searchtermeu").val();
            setSearchHistory(typing, $searchDestination, $searchHistoryStorageItemName, "q");
            getSuggestions(typing);

            var redirectUrl = $searchDestination + "/?q=" + encodeURIComponent(typing);
            window.location.href = redirectUrl;
        }

        function removeSearchHistoryForProductSearch(index) {
            var searchHistory = getSearchHistory($searchHistoryStorageItemName);
            searchHistory.splice(index, 1);
            localStorage.setItem($searchHistoryStorageItemName, JSON.stringify(searchHistory));
        }

        function removeSearchHistory(index) {
            var searchHistory = getSearchHistory($searchHistoryStorageItemName);
            searchHistory.splice(index, 1);
            localStorage.setItem($searchHistoryStorageItemName, JSON.stringify(searchHistory));
            renderSearchHistory();
        }

        function renderSearchHistory() {
            var searchHistory = getSearchHistory($searchHistoryStorageItemName);
            var searchHistoryContainer = $("#searchHistoryContainer");

            if (searchHistory.length > 0) {

                var dynamicHeading = $("#dynamicHeading").data("heading");

                var historyHtml = '<h2 class="history-heading">' + dynamicHeading + '</h2><ul>';
                for (var i = 0; i < searchHistory.length; i++) {
                    historyHtml += '<li><a href="' + searchHistory[i].link + '">' + searchHistory[i].term + '</a> <span class="remove" data-index="' + i + '"></span></li>';
                }
                historyHtml += '</ul>';

                searchHistoryContainer.html(historyHtml);

                $(".remove").on("click", function () {
                    var index = $(this).data("index");
                    removeSearchHistory(index);
                });
                function setSearchInputValue(searchTerm) {
                    $("#searchtermeu").val(decodeURIComponent(searchTerm));
                }
                function triggerSearch() {
                    search();
                }

                $("#searchHistoryContainer a").on("click", function (event) {
                    var searchTerm = $(this).text();
                    console.log('Selected search term:', searchTerm);
                    setSearchInputValue(searchTerm);
                    triggerSearch();
                    return false;
                });
            } else {
                searchHistoryContainer.html('');
            }
        }
    });

    function resizeHandler() {
        var isMobile = $(window).width() < 577;
        if (isMobile) {
            $('.eu-search-reults-wrapper .pagination-container .page-number').hide();
            $('.eu-search-reults-wrapper .pagination-container .page-number:lt(3)').show();
        } else {
            $('.eu-search-reults-wrapper .pagination-container .page-number').show();
        }
    }

    $(window).resize(resizeHandler);
}