$(document).ready(function () {
    
    // Check consent status on page load
    if (sessionStorage.getItem('cookieConsent') === 'true') {
        $('#onetrust-consent-sdk').hide();
    }

    // Handle consent button click
    $('#onetrust-banner-sdk button').click(function () {
        if (window.location.href.indexOf("searchterm") !== -1) {

            // Save consent status
            sessionStorage.setItem('cookieConsent', 'true');

            // Hide the cookie container
            $('#onetrust-consent-sdk').hide();
        } else {
            sessionStorage.removeItem('cookieConsent');
        }
    });

});