/* eslint-disable no-console */
import { getUrlParameter } from './_get-url-parameter.js';
import * as hb from 'handlebars/dist/handlebars';
import { discontinued } from './_discontinued.js';
import { sanitize } from './_sanitize.js';
import { stringInject } from './_stringInject.js';
import { createSeriesJumpLinks } from './series-jump-links.js';
import { setSearchHistory } from '../inc/search-history.js';
const filter = function (gridName) {
    $.each(gridName, function (index, result) {
        const $htbSrc = $(result).find('.js-results-template').html();
        const $selectModel = $(result).find('.js-select-model-template').html();
        const $results = $(result).find('.js-results');
        const $target = $results.find('.js-results-target');
        const $htbFiltersSrc = $(result).children('.js-filters__wrapper').children('.js-filters').children('.js-filters-template').html();
        const $filterContainer = $(result).find('.js-filters');
        const $dataPath = $(result).children('.js-results').data('endpoint');
        const $dataParent = $(result).children('.js-results').data('parent');
        const $buttonsContainer = $(result).children('.js-filter-buttons__container');
        const $resultsCounter = $('.js-filtered-grid').find('.js-results-count');
        const $resultsCounterText = $('.js-filtered-grid').find('.js-results-count').html();
        const $loadMore = $(result).find('.js-load-more__container');
        const $noResults = $(result).find('.js-no-results');
        const $paginationContainer = $(result).find('.js-pagination_list');
        const plainUrl = window.location.href.split('?')[0];
        const $wattsSearchHistoryStorageItemName = ($('body').attr('data-website') + "-" + $('.header-serve-history').attr('data-header-name')).replace(/\s+/g, '-').toLowerCase();
        const $wattsSearchDestination = ($('.js-search-bar__form').length) ? $('.js-search-bar__form').attr('action') : "/search";
        let values = [];
        let pagevalues = [];
        let results = [];
        let pagenumbers = [];
        let backButtonPressed = false;
        let isFirstPage = true;
        let pageNumber = getUrlParameter('Page');
        let searchQuery = getUrlParameter('searchterm');
        if (!searchQuery) {
            searchQuery = localStorage.getItem("searchValue");
        } else {
            searchQuery = sanitize(searchQuery);
            localStorage.setItem("searchValue", searchQuery);
        }
        let searchTermValue = searchQuery;
        const discontinuedFilterGroup = $filterContainer.data('disc-facet-label');
        const discontinuedFilterLabel = $filterContainer.data('disc-show-value-label');
        let isDiscontinued = false;
        let isLoadmore = false;

        const archivedFilterGroup = $filterContainer.data('archived-facet-label');
        const archivedFilterLabel = $filterContainer.data('archived-show-value-label');

        let filterHolder;


        if ($filterContainer.data('series-is-disc') && $filterContainer.data('series-is-disc').toLowerCase() === 'true') {
            isDiscontinued = true;
        }

        /* check for page-number------------------------------------------------*/
        if (!pageNumber) {
            pageNumber = 0;
        }

        /* Make Results---------------------------------------------------------//
        //                        Called by ajax .done                          //
        //----------------------------------------------------------------------*/

        const makeResults = function (data) {


            const Lstdiscontinued = $.each(data.Results, function (i, n) {
                return n.isDiscontinued === true;
            });

            const LstNotdiscontinued = $.each(data.Results, function (i, n) {
                return n.isDiscontinued === false;
            });

            //if (discontinued.length > 0 && Notdiscontinued.length < 1) {
            //    console.log("Discontinued" + discontinued.length + " Notdiscontinued " + Notdiscontinued.length);
            //}
            filterHolder = data.Facets;

            /* -----------------------------------------------------(pagination)*/
            pageNumber = data.Query.Page;
            const endCount = data.EndCount;
            const totalResults = data.TotalResults;


            if ($resultsCounter.length) {
                const newText = $resultsCounterText.replace('{total}', totalResults);
                $resultsCounter.html(newText);
            }
            const $datacounttext = $(result).find('.js-pagination-showing-results__container');
            const $datcounttext = $(result).find('.js-showing-results__container');
            //var counttext = $(result).find('.js-showing__resultstext').val();
            //counttext = stringInject(counttext, { part: endCount });
            //counttext = stringInject(counttext, { totalresults: totalResults });
            //$datcounttext.html(counttext)

            /* MAKE FILTERS------------------------(Filters handlebars-template)*/
            const filterTemplate = hb.compile($htbFiltersSrc);
            const newFilter = filterTemplate(data);
            $filterContainer.html(newFilter);

            const hasDiscontinuedResults = data.HasDiscontinuedResults;
            const hasArchivedResults = data.HasArchivedResults;

            /* Check to see if product is discontinued if not make filter-------*/
            if (hasDiscontinuedResults === true && isDiscontinued === false && discontinuedFilterGroup) {
                discontinued.writeFilter($filterContainer, discontinuedFilterGroup, discontinuedFilterLabel);
            }

            /* make archived filter if there are archived results */
            if (hasArchivedResults === true && archivedFilterGroup) {
                discontinued.writeFilter($filterContainer, archivedFilterGroup, archivedFilterLabel);
            }

            if ($filterContainer.find('.filter').length <= 0) {
                $(result).children('.js-filters__wrapper').children('.js-filter-label, .js-filters, .js-filter-label').addClass('is-hidden');
            } else {
                $(result).children('.js-filters__wrapper').children('.js-filter-label, .js-filters, .js-filter-label').removeClass('is-hidden');
            }

            /* MAKE RESULTS------------------------(Results handlebars-template)*/
            const template = hb.compile($htbSrc);
            const newResult = template(data);

            console.log("new result::::", $(newResult));

            if (!!$selectModel) {
                const selectModelTemplate = hb.compile($selectModel);
                const selectModelResult = selectModelTemplate(data);
                $(result).find('.js-select-model-target').append(selectModelResult);
            }

            /* STORE PAGE RESULTS IN LOCAL STORAGE FOR PAGINATION ----------------------------------------------*/
            if (!isFirstPage) {
                $results.find('.js-video__wrapper').addClass('bound');
                if (isLoadmore == true) {
                    $results.children('.js-results-target').append(newResult);
                } else {
                    var oldresult = $results.children('.js-results-target').html();
                    var pagination = $('.setpages__container .active').text();
                    //if (localStorage.getItem("oldresults") !== null  && localStorage.getItem("pagination") !== null && !backButtonPressed) {
                    if (!backButtonPressed && results.length > 0) {
                        if (localStorage.getItem("oldresults") !== "") {
                            values = JSON.parse(localStorage.getItem("oldresults"));
                            values.push(oldresult);
                        } else {
                            values = [];
                        }

                        if (localStorage.getItem("pagination") !== "") {
                            pagevalues = JSON.parse(localStorage.getItem("pagination"));
                            pagevalues.push(pagination);
                        } else {
                            pagevalues = [];
                        }

                    }
                    else if (!backButtonPressed) {
                        values.push(oldresult);
                        pagevalues.push(pagination);
                    }
                    $results.children('.js-results-target').html(newResult);
                    var searchTermValue = localStorage.getItem("searchValue");
                    if (searchTermValue != "" && window.location.href.includes("search")) {
                        $('.main-container').highlight(searchTermValue.toLowerCase());
                    }
                    if (!backButtonPressed) {
                        localStorage.setItem("oldresults", JSON.stringify(values));
                        results = JSON.parse(localStorage.getItem("oldresults"));
                        localStorage.setItem("pagination", JSON.stringify(pagevalues));
                        pagenumbers = JSON.parse(localStorage.getItem("pagination"));
                    }
                    backButtonPressed = false;
                }
                $datcounttext.removeClass('is-hidden');
                $resultsCounter.removeClass('is-hidden');
                $loadMore.removeClass('is-hidden');
                $paginationContainer.removeClass('is-hidden');
                $datacounttext.removeClass('is-hidden');
            }else if (totalResults < 1) {
                $target.empty();
                $noResults.removeClass('is-hidden');
                $resultsCounter.addClass('is-hidden');
                $datcounttext.addClass('is-hidden');
                $(result).find('.js-select-copy').addClass('is-hidden');
                $(result).find('.js-select-model-link').addClass('is-hidden');
            } else {
                $results.children('.js-results-target').html(newResult);
                var searchTermValue = localStorage.getItem("searchValue");
                if (searchTermValue != "" && window.location.href.includes("search")) {
                    $('.main-container').highlight(searchTermValue.toLowerCase());
                }
                $datcounttext.removeClass('is-hidden');
                $resultsCounter.removeClass('is-hidden');
                $loadMore.removeClass('is-hidden');
                $paginationContainer.removeClass('is-hidden');
                $datacounttext.removeClass('is-hidden');
            }
            let storedVideoHtml = '';
            let lastFocusedDiv = null;

            // Loop through the .grid-item elements and onclick of .grid-item a modal window will open and play that respective video on modal window
            $('.grid-item').each(function (index, element) {

                let gridEle = $(element).hasClass("video-on-modal");
                if ($(gridEle).length) {
                    $(element).attr('tabindex', '0');
                    $(element).on('click', function (event) {
                        event.preventDefault();
                        lastFocusedDiv = $(this);
                        storedVideoHtml = $(this).attr("data-video-markup");
                        $('#showVideoModal').modal('show');

                    });

                    // Keydown event for Enter and Space
                    $(element).on('keydown', function (e) {
                        if (e.key === 'Enter' || e.key === ' ') {
                            e.preventDefault();
                            $(this).click();
                        }
                    });

                    $('#showVideoModal').on('shown.bs.modal', function () {
                        // Append the stored HTML to the modal body
                        $(this).find('.show-video-modal-content').html(storedVideoHtml);
                    });

                    $('#showVideoModal').on('hidden.bs.modal', function () {
                        var videoContainer = $(this).find('.js-video.video')[0];
                        videoContainer.pause(); // Pause the video
                        videoContainer.currentTime = 0; // Optionally reset the video to the start
                        if (lastFocusedDiv) {
                            lastFocusedDiv.focus(); // Return focus to the previously clicked div
                        }
                    });
                }
                
            });

            // Use event delegation to bind click event to .grid-item-child inside .grid-item
           /* $(document).on('click', '.grid-item .grid-item-child', function () {
                // Custom click functionality for the .grid-item-child
                console.log("Clicked on .grid-item-child", $(this).html());

                // Open the Bootstrap modal
                $('#myModal').modal('show');
            });*/

            isFirstPage = false;
            
            /* LOAD MORE----------------------------------------------------//
            //--------------------------------------------------------------*/
            
            const oldUrl = window.location.href;
            const nextPage = parseInt(pageNumber) + 1;
            const oldNumber = `Page=${parseInt(pageNumber) - 1}`;
            const newNumber = `Page=${pageNumber}`;
            const newUrl = oldUrl.replace(oldNumber, newNumber);
            history.replaceState(null, null, newUrl);

            if (endCount < totalResults) {
                if (!$(result).find('.product-results').hasClass('is-hidden')) {
                    $loadMore.show();
                }
                $loadMore.children(".js-load-more__button").off("click").click(function (event) {
                    event.preventDefault();
                    isLoadmore = true;
                    getResults(nextPage);                    
                });
            } else {
                $loadMore.addClass('no-more').hide();
            }

            /* PAGINATION ----------------------------------------------------//
           //--------------------------------------------------------------*/
            /* for getting stored site search page size -------------------*/
            var resultsPerPage = localStorage.getItem("SiteSearchPageSize");
            if (!resultsPerPage) {
                resultsPerPage = !!$("#SelectResultCount").val() ? parseInt($("#SelectResultCount").val()) : 30;
                localStorage.setItem("SiteSearchPageSize", resultsPerPage);
            }
            $("#SelectResultCount").val(resultsPerPage);
            if ($("#SelectResultCount").length > 0) {
                resultsPerPage = $("#SelectResultCount").val();
            }

            /* get stored sort by & sort order components value -------------------------*/
            var prodSortByStorage = localStorage.getItem("productsSortBy");
            if (!prodSortByStorage && !getUrlParameter('Page')) {
                localStorage.setItem("productsSortBy", "ProductName");
                localStorage.setItem("productsLinkSortOrder", "ascending");
            } else {
                if (!!prodSortByStorage) {
                    $("#SelectSortBy").val(prodSortByStorage);
                }
                if (!!getUrlParameter('Page')) {
                    $(document).ready(function () {
                        setTimeout(() => {
                            $("html,body").animate({ scrollTop: $('.select-result-count').offset().top + 30, scrollLeft: 0 }, 800);
                        }, 1000);
                    });                    
                }                
            }

            var pagenumbersPerPage = 5;
            var totalpagenumbers = Math.ceil(totalResults / resultsPerPage);
            var totalsetfivenumbers = Math.ceil(totalpagenumbers / pagenumbersPerPage);

            let searchQuery = getUrlParameter('searchterm');
            if (searchQuery) {
                searchQuery = sanitize(searchQuery);
            }

            let filterQuery = getUrlParameter('Filters');
            filterQuery = !!filterQuery ? encodeURIComponent(filterQuery) : '';
            $(".js-pagination_list").children().remove();
            var $prev,$prevchild;
            $prev = $('<li></li>');
            $prevchild = $('<a class="previousarrow" href="' + prepareNavHref(searchQuery, filterQuery, pageNumber) + '"aria-label="' + "previous page"+'">&lt;</a>');

            if (pageNumber < 1) {
                $prevchild = $('<a class="previousarrow" href="#" aria-hidden="true" tabindex="-1">&lt;</a>').css({ "color": "grey", "text-decoration": "none" }).addClass('disabled');
            }
            $prev.append($prevchild);
            $paginationContainer.append($prev);
            var exactpage;
            let w = 1;
            for (let y = 1; y <= totalsetfivenumbers; y++) {
                var $setvalue = $('<li class="totalsetpages"></li>');
                var $setchildvalue = $('<div class="setpages__container set' + y + '"' + '>' + '</div>');
                let z;
                $paginationContainer.append($setvalue);
                for (z = w; z <= y * pagenumbersPerPage; z++) {
                    var $pagevalue = $('<a id="pagevalue' + z + '" href="' + prepareNavHref(searchQuery, filterQuery, z) + '"aria-label="' + "page " + z + '"aria-hidden="' + "false" +'">' + z + '</a>');
                    if (z == pageNumber+1) {
                        $pagevalue.addClass('active disabled');
                        $pagevalue.attr('aria-hidden', 'true');
                        $pagevalue.attr('tabindex', '-1');
                        $setchildvalue.addClass('selected');
                        exactpage = y - 1;
                    }
                    if (z > totalpagenumbers) {
                        $pagevalue.css({ "color": "grey", "text-decoration": "none" }).addClass('disabled');
                        $pagevalue.attr('aria-hidden', 'true');
                        $pagevalue.attr('tabindex', '-1');
                    }
                    $setchildvalue.append($pagevalue);
                    $setvalue.append($setchildvalue);
                }
                w=z++;
            }
            var $next,$nextchild;
            $next = $('<li></li>');
            $nextchild = $('<a class="nextarrow" href="' + prepareNavHref(searchQuery, filterQuery, (pageNumber + 2)) + '"aria-label="' + "next page"+'">&gt;</a>');
            if ((pageNumber >= totalpagenumbers-1)) {
                $nextchild = $('<a class="nextarrow" href="#" aria-hidden="true" tabindex="-1">&gt;</a>').css({ "color": "grey", "text-decoration": "none" }).addClass('disabled');
            }
            $next.append($nextchild);
            $paginationContainer.append($next);

            $(".totalsetpages").each(function (e) {
                if (e != exactpage)
                    $(this).hide();
            });

            var resultstext = $(result).find('.js-showing__resultstext').val();

            resultstext = stringInject(resultstext, { totalresults: totalResults });
            if (endCount <= resultsPerPage) {
                resultstext = stringInject(resultstext, { startcount: 1 });
            }
            else if (endCount >= totalResults)
            {
                var prevendcount = (Math.floor(endCount / resultsPerPage)) * resultsPerPage;
                resultstext = stringInject(resultstext, { startcount: prevendcount+1 });
            }
            else {
                resultstext = stringInject(resultstext, { startcount: endCount - (resultsPerPage-1) });
            }
            resultstext = stringInject(resultstext, { endcount: endCount });
            resultstext = stringInject(resultstext, { part: endCount });

            $datacounttext.html(resultstext)

            /* Inital state for repait kit accordion----------------------------*/
            $(result).find('.js-repair-kit__list').css('display', 'none');

            createSeriesJumpLinks();

            if ($('.modes-mode').length > 0) {
                var searchviewmode = JSON.parse(sessionStorage.getItem("searchviewmode"));
                console.log(searchviewmode);
                if (searchviewmode && (location.href).indexOf(searchviewmode.pageName) >= 0) {

                    if (searchviewmode.isListView == true || searchviewmode.isListView == "true") {
                        //add class to body here
                        $("body").addClass("modes-list");
                        $("a#mode-grid").removeClass("active");
                        $("a#mode-list").addClass("active");
                        $(".results-target .list-view-material").css("display", "block");
                        $(".results-target .grid-view-material").css("display", "none");
                    }
                    else {
                        $("body").addClass("modes-grid");
                        $("a#mode-list").removeClass("active");
                        $("a#mode-grid").addClass("active");
                        $(".results-target .list-view-material").css("display", "none");
                        $(".results-target .grid-view-material").css("display", "block");
                    }
                }
                else {
                    if (window.matchMedia("(max-width: 767px)").matches) {
                        //add class to body here
                        $("body").addClass("modes-list");
                        $("a#mode-grid").removeClass("active");
                        $("a#mode-list").addClass("active");
                        $(".results-target .list-view-material").css("display", "block");
                        $(".results-target .grid-view-material").css("display", "none");
                    }
                    else {
                        $("body").addClass("modes-grid");
                        $("a#mode-list").removeClass("active");
                        $("a#mode-grid").addClass("active");
                        $(".results-target .list-view-material").css("display", "none");
                        $(".results-target .grid-view-material").css("display", "block");
                    }
                }
            }
            
        };// Make Results

        /* get filter query and update page url with page number for pagination ----------------------*/
        const prepareNavHref = function (searchQuery, filterQuery, pageNo) {
            const newSAction = $('.js-search-bar__form').attr('action');
            let pageUrl = !!searchQuery ? '/search?searchterm=' + searchQuery : window.location.pathname;
            if (newSAction != null && newSAction != '') {
                pageUrl = !!searchQuery ? newSAction + '?searchterm=' + searchQuery : window.location.pathname;
            }
            
            if (!!filterQuery) {
                pageUrl += (pageUrl.indexOf('?') != -1 ? '&' : '?') + 'Filters=' + filterQuery;
            }
            pageUrl += (pageUrl.indexOf('?') != -1 ? '&' : '?') + 'Page=' + pageNo;
            return pageUrl;
        };

        /* MAIN GET RESULTS FUNCTION--------------------------------------------//
        //----------------------------------------------------------------------*/
        const getResults = function (pageNumber) {

            let filtersArray = [];

            /* -------------------------------------Clear buttons and checkboxes*/
            if ($buttonsContainer.length) {
                $buttonsContainer.children('.js-filter-button').remove();
            }

            $filterContainer.find('.js-filter__input').each(function (index, checkbox) {
                $(checkbox).prop('checked', false);
            });

            /* --------------------------------------------Check url for filters*/
            const newFilters = getUrlParameter('Filters');
            let categoryObject;

            if (newFilters) {
                categoryObject = JSON.parse(getUrlParameter('Filters'));
                $(result).find('.product-results').removeClass('is-hidden');
                $(result).find('.js-select-copy').removeClass('is-hidden');
            }// If filters

            /* ASYNC FIX--------------------------------------------------------*/
            $.ajaxSetup({ // this rids us of an async issue
                async: open
            });

            /* READY THE DATA---------------------------------------------------//
            //------------------------------------------------------------------*/
            const requestData = { Page: pageNumber };
            if ($dataParent) {
                requestData.Parent = $dataParent;
            }
            if (categoryObject) {
                requestData.Facets = categoryObject;
            }
            let searchQuery = getUrlParameter('searchterm');
            let brandInQuery = getUrlParameter('brand');
            if (searchQuery) {
                searchQuery = sanitize(searchQuery);
                requestData.SearchTerm = searchQuery;
            }
            if (brandInQuery) {
                brandInQuery = sanitize(brandInQuery);
                requestData.Brand = brandInQuery;
            }
            //  requestData.DataSourceId = "{015F5DEC-52A2-4BB4-B814-B9804FCFC3F3}";
            requestData.DataSourceId = $(result).find('.js-datasourceid').val();

            var sortBy = "ProductName";
            var itemsPerPage = !!$("#SelectResultCount").val() ? $("#SelectResultCount").val() : '30';
            var sortOrder = "Ascending";

            sortBy = !!localStorage.getItem("productsSortBy") ? localStorage.getItem("productsSortBy") : $("#SelectSortBy").val();
            sortOrder = !!localStorage.getItem("productsLinkSortOrder") ? localStorage.getItem("productsLinkSortOrder") : $(".link-sort").attr("data-sort-order");
            if (!!sortBy) {
                requestData.SortBy = sortBy;
                requestData.SortOrder = sortOrder;
                if (sortOrder == 'ascending') {
                    var titleVal = $(".link-sort").attr("data-ascending-text")
                    $('.link-sort').removeClass('up');
                    $('.link-sort').addClass('down');
                    $(".link-sort").attr("title", titleVal);
                    $(".link-sort").attr("data-sort-order", "ascending");
                } else {
                    var titleVal = $(".link-sort").attr("data-ascending-text")
                    $('.link-sort').removeClass('down');
                    $('.link-sort').addClass('up');
                    $(".link-sort").attr("title", titleVal);
                    $(".link-sort").attr("data-sort-order", "descending");
                }
            }

            /* get page size from local storage to load page with same page size for pagination -------------------------*/
            let itemsPerPageStorage = localStorage.getItem("SiteSearchPageSize");
            if (!!itemsPerPageStorage) {
                itemsPerPage = itemsPerPageStorage; // $("#SelectResultCount").val();
                requestData.PageSize = itemsPerPage;
            } else {
                requestData.PageSize = !!$("#SelectResultCount").val() ? $("#SelectResultCount").val() : '30';
            }

            const disc = getUrlParameter('Filters');
            if (disc) {
                const discArray = JSON.parse(disc);
                if (discArray.length > 0) {
                    requestData.ShowDiscontinued = discontinued.getTruth(discontinuedFilterLabel, discArray);
                    requestData.ShowArchived = discontinued.getTruth(archivedFilterLabel, discArray);
                }
            }

            /* MAKE THE CALL TO THE BACKEND SERVICE-----------------------------//
            //------------------------------------------------------------------*/

            $.ajax({
                type: "POST",
                url: $dataPath,
                data: requestData,
                beforeSend: function () {
                    if (!$(result).find('.loader-wrapper').length) {
                        $results.append('<div class="loader-wrapper"><div class="loader"></div></div>');
                    } else {
                        $(result).find('.loader-wrapper').fadeIn('fast');
                    }
                },
                cache: false
            }).done(function (data) {

                $(result).find('.loader-wrapper').fadeOut('slow');

                //Add into search history
                if (searchQuery != null && searchQuery != "") {
                    setSearchHistory(searchQuery, $wattsSearchDestination, $wattsSearchHistoryStorageItemName, "searchterm");
                }

                //////////////webdev-168 fix start///////////////
                let isfiltrflowenabled = "no";
                isfiltrflowenabled = document.getElementsByTagName("body")[0].getAttribute("data-filterflow");
                if (isfiltrflowenabled === "yes") {
                    const p = getUrlParameter('Filters');
                    if (p != null && p != '') {
                        $.each(data.Results, function (k, v) {
                            let paramFilters = p;
                            let surl = v.Url;
                            let strparam = "";

                            let appfiltrs = v.ApplicableFilters;
                            if (appfiltrs != null && appfiltrs != '') {
                                let paramarray = [];
                                let delarray = [];
                                let filtattrarray = appfiltrs.split("|");

                                paramarray = JSON.parse(paramFilters);
                                let isdel = 1;
                                let icount = paramarray.length;
                                let delcount = 0;

                                for (let i = 0; i < icount; i++) {
                                    isdel = 1;
                                    for (let j = 0; j < filtattrarray.length; j++) {
                                        if (paramarray[i]["Name"].toLowerCase() === filtattrarray[j].toLowerCase()) {
                                            isdel = 0;
                                            break;
                                        }
                                    }
                                    if (isdel === 1) {
                                        delarray[delcount] = i;
                                        delcount = delcount + 1;
                                    }
                                }

                                if (delarray.length > 0) {
                                    for (let k = 0; k < delarray.length; k++) {
                                        paramarray.splice(delarray[k], 1);
                                    }
                                }

                                if (paramarray.length > 0) {
                                    strparam = JSON.stringify(paramarray);
                                }
                            }
                            if (strparam != null && strparam != '') {
                                let filtrsparam = encodeURIComponent(strparam);

                                let isfiltrfix = "no";
                                isfiltrfix = document.getElementsByTagName("body")[0].getAttribute("data-filterfix");

                                if (isfiltrfix === "yes" && (filtrsparam.toLowerCase().indexOf("name") >= 0 || filtrsparam.toLowerCase().indexOf("values") >= 0)) {
                                    if (filtrsparam.toLowerCase().indexOf('"name"%3a') >= 0) {
                                        filtrsparam = filtrsparam.replace(new RegExp('"Name"%3A', 'gi'), "Name%3A");
                                    }
                                    else if (filtrsparam.toLowerCase().indexOf('"name":') >= 0) {
                                        filtrsparam = filtrsparam.replace(new RegExp('"Name":', 'gi'), "Name:");
                                    }
                                    else if (filtrsparam.toLowerCase().indexOf('%22name%22%3a') >= 0) {
                                        filtrsparam = filtrsparam.replace(new RegExp('%22Name%22%3A', 'gi'), "Name%3A");
                                    }
                                    else if (filtrsparam.toLowerCase().indexOf('%22name%22:') >= 0) {
                                        filtrsparam = filtrsparam.replace(new RegExp('%22Name%22:', 'gi'), "Name:");
                                    }

                                    if (filtrsparam.toLowerCase().indexOf('"values"%3a') >= 0) {
                                        filtrsparam = filtrsparam.replace(new RegExp('"Values"%3A', 'gi'), "Values%3A");
                                    }
                                    else if (filtrsparam.toLowerCase().indexOf('"values":') >= 0) {
                                        filtrsparam = filtrsparam.replace(new RegExp('"Values":', 'gi'), "Values:");
                                    }
                                    else if (filtrsparam.toLowerCase().indexOf('%22values%22%3a') >= 0) {
                                        filtrsparam = filtrsparam.replace(new RegExp('%22Values%22%3A', 'gi'), "Values%3A");
                                    }
                                    else if (filtrsparam.toLowerCase().indexOf('%22values%22:') >= 0) {
                                        filtrsparam = filtrsparam.replace(new RegExp('%22Values%22:', 'gi'), "Values:");
                                    }
                                }
                                if (surl != null && surl != "") {
                                    if (surl.toLowerCase().indexOf("filters") < 0) {
                                        if (surl.toLowerCase().indexOf("?") < 0) {
                                            v.Url = surl + "?Filters=" + filtrsparam;
                                        }
                                        else {
                                            v.Url = surl + "&Filters=" + filtrsparam;
                                        }
                                    }
                                }
                            }
                        });
                    }
                }
                //////////////webdev-168 fix End///////////////

                if (!data) {
                    $noResults.removeClass('is-hidden');
                    $(result).find('.js-showing-results__container').addClass('is-hidden');
                    $(result).children('.js-filters__wrapper').children('.js-results-count').addClass('is-hidden');
                    $(result).find('.js-select-copy').addClass('is-hidden');
                    $target.empty();
                    return;
                }
                else {
                    $noResults.addClass('is-hidden');
                }

                makeResults(data);

                if ($(".quick-filter-tabs").length) {
                    // Ensure checkboxes with value="true" have their parent div hidden
                    $(".js-filter__input[value='true']").closest(".js-filter.filter").hide();
                }

            }).then(function (data) {

                /// begin


                $('.js-filter__inputs').slideUp();
                $('.js-filter__label').attr('aria-expanded', 'false');
                var first = $filterContainer.children().first();
                var firstlabel = first.children().first();
                firstlabel.addClass('.js-filter__label filter__label is-open');
                firstlabel.attr('aria-expanded', 'true');
                var firstdropdown = first.find('.filter__inputs');
                firstdropdown.slideDown();
                ///  end



                /* Get Filters--------------------------------------------------//
                // This is where we check filters against the array from the url//
                //--------------------------------------------------------------*/
                const getFilters = function (data) {

                    const resultCount = data.TotalResults;
                    $filterContainer.each(function (index, facet) {
                        const $input = $(facet).find('.js-filter__input');
                        const checkedFilters = getUrlParameter('Filters');

                        if (checkedFilters) {
                            filtersArray = JSON.parse(checkedFilters);

                            /* Check Filters that are in the url----------------*/
                            $input.each(function (index, filter) {
                                for (let i = 0; i < filtersArray.length; i++) {
                                    if (($.inArray($(filter).val(), filtersArray[i].Values) !== -1) && ($(filter).data('facetname') === filtersArray[i].Name)) {
                                        $(filter).prop('checked', true); // Check the box
                                        const facetName = $(filter).data('facetname');
                                        const name = $(filter).val();
                                        const displayName = $(filter).parent('label').text();
                                        const label = $(filter).parent();
                                        const fiteritem = $(label).parent();
                                        const filterInputs = $(fiteritem).parent();
                                        const filterLabel = $(filterInputs).siblings();
                                        $(filterLabel).addClass('.js-filter__label filter__label is-open')
                                        $(filterInputs).slideDown();
                                        if ($buttonsContainer.length && `${name}` !== "true") {
                                            $buttonsContainer.append(`<a class="js-filter-button filter-button" data-name="${name}" data-facetname="${facetName}">${displayName}</a>`); // Add the Button
                                        }
                                    }
                                } // For "i"
                            });// Each input

                            if (resultCount === 0) {
                                for (let i = 0; i < filtersArray.length; i++) {
                                    $('.js-filter__label').each(function () {
                                        if ($(this).attr('data-name') != undefined && $(this).attr('data-name').toLowerCase() == filtersArray[i].Name.toLowerCase()) {
                                            if ($(this).next('.js-filter__inputs').find('.js-filter__item').length <= 0) {
                                                $(this).next('.js-filter__inputs').append('<div class="js-filter__item filter__item"><label><input type="checkbox" checked="checked" class="js-filter__input filter__input track_filter" value="' + filtersArray[i].Values[0] + '" data-facetname="' + filtersArray[i].Name + '">' + filtersArray[i].Values[0] +'</label></div>');
                                            }
                                        }
                                    });
                                }
                            }

                        }// Checked filters
                        else {
                            //Code to check the discontinued products if no results found.
                            $input.each(function (index, filter) {
                                if ($(filter).data('facetname').toLowerCase() === 'show discontinued' && $(filter)[0].checked != true) {
                                    if (data.Query.ShowDiscontinued) {
                                        $(filter).prop('checked', true); // Check the box
                                        const facetName = $(filter).data('facetname');
                                        const name = $(filter).val();
                                        const displayName = $(filter).parent('label').text();
                                        const label = $(filter).parent();
                                        const fiteritem = $(label).parent();
                                        const filterInputs = $(fiteritem).parent();
                                        const filterLabel = $(filterInputs).siblings();
                                        $(filterLabel).addClass('.js-filter__label filter__label is-open')
                                        $(filterInputs).slideDown();
                                        if ($buttonsContainer.length && `${name}` !== "true") {
                                            $buttonsContainer.append(`<a class="js-filter-button filter-button" data-name="${name}" data-facetname="${facetName}">${displayName}</a>`); // Add the Button
                                        }
                                    }
                                }
                            });
                        }
                    });
                }; // Get Filters


                getFilters(data);
                // $('.js-filter__label').addClass('is-open');

                if (document.querySelector("meta[name=ps-key]")) {
                    PriceSpider.rebind();
                }

            }).fail(function (xhr, textStatus, errorThrown) {
                $target.empty();
                $loadMore.hide();
                $noResults.removeClass('is-hidden');
                $(result).find('.js-select-copy').addClass('is-hidden');
                $results.find('.loader-wrapper').fadeOut('slow');
            });// After Ajax call
        };// Get Results

        /* load and update page search for pagination------------------------------*/
        const pageParamVal = getUrlParameter('Page');
        const pageToLoad = (!!pageParamVal && parseInt(pageParamVal) != 0) ? parseInt(pageParamVal) - 1 : 0;
        var url = window.location.href;
        window.history.pushState({ path: url }, '', url);
        const pathname = window.location.pathname;
        const pathnameInStore = localStorage.getItem("prevpathname");
        if (!!pathname && !!pathnameInStore && !(pathname.startsWith(pathnameInStore) || pathnameInStore.startsWith(pathname))) {
            localStorage.clear();
        }
        localStorage.setItem('prevpathname', pathname);
        if (url.includes('search')) {
            localStorage.setItem("searchValue", searchTermValue);
        } else {
            localStorage.removeItem("searchValue");
        }
        getResults(pageToLoad);

        //my code
        $('.js-sortby').on('change', function (event) {
            if ($(this).val() == 'Popularity') {
                if ($('#lnksort').hasClass('down')) {
                    var titleVal = $(".link-sort").attr("data-descending-text")
                    $('#lnksort').removeClass('down');
                    $('#lnksort').addClass('up');
                    $(".link-sort").attr("title", titleVal);
                    $(".link-sort").attr("data-sort-order", "descending");
                    localStorage.setItem("productsLinkSortOrder", "descending");
                }
            }
            /* set sort by component value in the local storage and update page url --------------*/
            localStorage.setItem("productsSortBy", $(this).val());
            const currPageNoVal = getUrlParameter('Page');
            window.location.href = window.location.href.replace('Page=' + currPageNoVal, 'Page=0');
        });

        $('.select-result-count').on('change', function (event) {
            /* get page size for pagination and update the page url ------------------------------------ */
            let itemsPerPage = 9;
            if ($("#SelectResultCount").length > 0) {
                itemsPerPage = $("#SelectResultCount").val();
            }
            localStorage.setItem("SiteSearchPageSize", itemsPerPage);
            const currPageNoVal = getUrlParameter('Page');
            window.location.href = window.location.href.replace('Page=' + currPageNoVal, 'Page=0');
        });

        $('.link-sort').on('click', function (event) {
            if ($(this).hasClass('up')) {
                var titleVal = $(".link-sort").attr("data-ascending-text")
                $(this).removeClass('up');
                $(this).addClass('down');
                $(".link-sort").attr("title", titleVal);
                $(".link-sort").attr("data-sort-order", "ascending");
                localStorage.setItem("productsLinkSortOrder", "ascending");
            }
            else if ($(this).hasClass('down')) {
                var titleVal = $(".link-sort").attr("data-descending-text")
                $(this).removeClass('down');
                $(this).addClass('up');
                $(".link-sort").attr("title", titleVal);
                $(".link-sort").attr("data-sort-order", "descending");
                localStorage.setItem("productsLinkSortOrder", "descending");
            }
            /* update page url with current page number ---------------------------*/
            const currPageNoVal = getUrlParameter('Page');
            window.location.href = window.location.href.replace('Page=' + currPageNoVal, 'Page=0');
        });

        /* FILTER ON CLICK------------------------------------------------------//
        //        Run the item function with the change of state on checkbox    //
        //----------------------------------------------------------------------*/

        $filterContainer.on("click", '.js-filter__input', function (e) {
            const checkedLabel = $(e.currentTarget).data('facetname');
            const checkedValue = $(e.currentTarget).val();
            let searchQuery = getUrlParameter('searchterm');
            if (searchQuery) {
                searchQuery = sanitize(searchQuery);
            }
            const tempFilters = getUrlParameter('Filters');
            let tempArray = [];

            if (tempFilters) {
                tempArray = JSON.parse(tempFilters);
            }

            let tempUrl;

            if ($(e.currentTarget).is(':checked')) {

                /* check to see if the label value is already in the url string and add it if not*/
                const obj = $.grep(tempArray, function (obj) { return obj.Name === checkedLabel; })[0];

                if (!obj) {
                    tempArray.push({ 'Name': checkedLabel, 'Values': [] });
                }

                /*  check to see if the filter exists in the array where the label is */
                for (let i = 0; i < tempArray.length; i++) {
                    if (tempArray[i]['Name'] === checkedLabel) {

                        if ($.inArray(checkedValue, tempArray[i]['Values']) === -1) {
                            tempArray[i]['Values'].push(checkedValue);
                        }

                    }
                }// For 'i'

                if (tempArray.length > 0) {
                    const strungFilters = encodeURIComponent(JSON.stringify(tempArray));
                    let filterParam = strungFilters;

                    let isfilterfix = "no";
                    isfilterfix = document.getElementsByTagName("body")[0].getAttribute("data-filterfix");

                    if (isfilterfix === "yes" && (filterParam.toLowerCase().indexOf("name") >= 0 || filterParam.toLowerCase().indexOf("values") >= 0)) {
                        if (filterParam.toLowerCase().indexOf('"name"%3a') >= 0) {
                            filterParam = filterParam.replace(new RegExp('"Name"%3A', 'gi'), "Name%3A");
                        }
                        else if (filterParam.toLowerCase().indexOf('"name":') >= 0) {
                            filterParam = filterParam.replace(new RegExp('"Name":', 'gi'), "Name:");
                        }
                        else if (filterParam.toLowerCase().indexOf('%22name%22%3a') >= 0) {
                            filterParam = filterParam.replace(new RegExp('%22Name%22%3A', 'gi'), "Name%3A");
                        }
                        else if (filterParam.toLowerCase().indexOf('%22name%22:') >= 0) {
                            filterParam = filterParam.replace(new RegExp('%22Name%22:', 'gi'), "Name:");
                        }

                        if (filterParam.toLowerCase().indexOf('"values"%3a') >= 0) {
                            filterParam = filterParam.replace(new RegExp('"Values"%3A', 'gi'), "Values%3A");
                        }
                        else if (filterParam.toLowerCase().indexOf('"values":') >= 0) {
                            filterParam = filterParam.replace(new RegExp('"Values":', 'gi'), "Values:");
                        }
                        else if (filterParam.toLowerCase().indexOf('%22values%22%3a') >= 0) {
                            filterParam = filterParam.replace(new RegExp('%22Values%22%3A', 'gi'), "Values%3A");
                        }
                        else if (filterParam.toLowerCase().indexOf('%22values%22:') >= 0) {
                            filterParam = filterParam.replace(new RegExp('%22Values%22:', 'gi'), "Values:");
                        }
                    }
                    if (searchQuery) {
                        tempUrl = `${plainUrl}?searchterm=${searchQuery}&Filters=${filterParam}&Page=0`;
                    } else {
                        tempUrl = `${plainUrl}?Filters=${filterParam}&Page=0`;
                    }
                } else {
                    if (searchQuery) {
                        tempUrl = `${plainUrl}?searchterm=${searchQuery}&Page=0`;
                    } else {
                        tempUrl = `${plainUrl}?Page=${pageNumber}`;
                    }
                }

            } else {
                for (let i = 0; i < tempArray.length; i++) {
                    if (tempArray[i]['Name'] === checkedLabel) {
                        if ($.inArray(checkedValue, tempArray[i]['Values']) !== -1) {
                            const remIndex = tempArray[i]['Values'].indexOf(checkedValue);
                            tempArray[i]['Values'].splice(remIndex, 1);
                        }
                        if (tempArray[i].Values.length <= 0) {
                            tempArray.splice(i, 1)
                        }
                    }
                }// For 'i'

                if (tempArray.length > 0) {
                    const strungFilters = encodeURIComponent(JSON.stringify(tempArray));
                    let filterParam = strungFilters;

                    let isfilterfix = "no";
                    isfilterfix = document.getElementsByTagName("body")[0].getAttribute("data-filterfix");

                    if (isfilterfix === "yes" && (filterParam.toLowerCase().indexOf("name") >= 0 || filterParam.toLowerCase().indexOf("values") >= 0)) {
                        if (filterParam.toLowerCase().indexOf('"name"%3a') >= 0) {
                            filterParam = filterParam.replace(new RegExp('"Name"%3A', 'gi'), "Name%3A");
                        }
                        else if (filterParam.toLowerCase().indexOf('"name":') >= 0) {
                            filterParam = filterParam.replace(new RegExp('"Name":', 'gi'), "Name:");
                        }
                        else if (filterParam.toLowerCase().indexOf('%22name%22%3a') >= 0) {
                            filterParam = filterParam.replace(new RegExp('%22Name%22%3A', 'gi'), "Name%3A");
                        }
                        else if (filterParam.toLowerCase().indexOf('%22name%22:') >= 0) {
                            filterParam = filterParam.replace(new RegExp('%22Name%22:', 'gi'), "Name:");
                        }

                        if (filterParam.toLowerCase().indexOf('"values"%3a') >= 0) {
                            filterParam = filterParam.replace(new RegExp('"Values"%3A', 'gi'), "Values%3A");
                        }
                        else if (filterParam.toLowerCase().indexOf('"values":') >= 0) {
                            filterParam = filterParam.replace(new RegExp('"Values":', 'gi'), "Values:");
                        }
                        else if (filterParam.toLowerCase().indexOf('%22values%22%3a') >= 0) {
                            filterParam = filterParam.replace(new RegExp('%22Values%22%3A', 'gi'), "Values%3A");
                        }
                        else if (filterParam.toLowerCase().indexOf('%22values%22:') >= 0) {
                            filterParam = filterParam.replace(new RegExp('%22Values%22:', 'gi'), "Values:");
                        }
                    }
                    if (searchQuery) {
                        tempUrl = `${plainUrl}?searchterm=${searchQuery}&Filters=${filterParam}&Page=0`;
                    } else {
                        tempUrl = `${plainUrl}?Filters=${filterParam}&Page=0`;
                    }
                } else {
                    if (searchQuery) {
                        tempUrl = `${plainUrl}?searchterm=${searchQuery}&Page=0`;
                    } else {
                        tempUrl = `${plainUrl}?Page=0`;
                    }
                }
            } // If unchecked

            history.replaceState(null, null, tempUrl);
            $(e.currentTarget).parents('.js-filters__wrapper').siblings('.js-select-copy').children('.js-show-all-button-container').addClass('is-hidden');
            getResults(0);
        });


        /* Show/Hide filter group-----------------------------------------------*/
        $filterContainer.on("click", '.js-filter__label', function (e) {
            if ($(e.target).hasClass('is-open')) {
                $(e.target).attr('aria-expanded','false');
                $(e.target).siblings('.js-filter__inputs').slideUp();
                // $(e.target).siblings('.js-filter__inputs').slideDown();
                $(e.target).removeClass('is-open');
            } else {
                $(e.target).addClass('is-open');
                $(e.target).siblings('.js-filter__inputs').slideDown();
                // $(e.target).siblings('.js-filter__inputs').slideUp();
                $(e.target).attr('aria-expanded','true');
            }
        });



        $(result).on("click", '.js-repair-kit__label', function (e) {
            if ($(e.target).hasClass('is-open')) {
                $(e.target).siblings('.js-repair-kit__list').slideUp();
                $(e.target).removeClass('is-open');
            } else {
                $(e.target).addClass('is-open');
                $(e.target).siblings('.js-repair-kit__list').slideDown();
            }
        });


        /* BUTTONS -------------------------------------------------------------//
        //            Set state for checkbox and remove button                  //
        //----------------------------------------------------------------------*/
        if ($buttonsContainer.length) {
            $buttonsContainer.on('click', '.js-filter-button', function (e) {
                const $element = $(e.currentTarget);
                const facetValue = $(e.currentTarget).attr('data-name');
                const facetName = $(e.currentTarget).data('facetname');
                const $targetContainer = $filterContainer

                $targetContainer.find(`[data-facetname="${facetName}"]`).each(function (index, element) {
                    if ($(element).val() === facetValue) {
                        $(element).trigger('click');
                    }
                });
            });// Buttons Container

        }

        /* Show/Hide product results--------------------------------------------*/
        const $showAllButtonContainer = $(result).find('.js-show-all-button-container');
        $showAllButtonContainer.on("click", '.js-show-all-button', function (e) {
            e.preventDefault();
            $(result).find('.product-results').removeClass('is-hidden');
            $(e.currentTarget).parent('.js-show-all-button-container').addClass('is-hidden');
            $(result).find('.js-load-more__container').not('.no-more').show();

            $("html,body").animate({ scrollTop: $('.js-results-target').offset().top - 20, scrollLeft: 0 }, 800);
        });

        /* Adding search term to the page on Site Search------------------------*/
        if ($(result).hasClass('site-search')) {
            const noSearchTerm = $(result).parent('.main-container').find('.js-page-title').data('empty');
            const searchTerm = $(result).parent('.main-container').find('.js-page-title').data('term');
            const heading = $(result).parent('.main-container').find('.js-page-title');
            let searchQuery = getUrlParameter('searchterm');

            if (searchQuery) {
                searchQuery = sanitize(searchQuery);
                heading.text(searchTerm.replace('{searchTerm}', `“${searchQuery}”`));
            } else {
                heading.text(noSearchTerm);
            }
        }

        if ($(result).hasClass('series-selector')) {
            $loadMore.hide();
        }
    });// Each Filtered Grid


};// Main Function

$('.js-print-results').on('click', function (event) {
    window.print();
});

const $gridName = $('.js-filtered-grid');

filter($gridName);
if (!$gridName || $gridName.length == 0) {
    createSeriesJumpLinks();
}

document.onsubmit = function () {
    if ($("form").hasClass("js-loaderOnFormSubmit")) {
        document.querySelectorAll('form input[type=submit]')[0].value = 'Please wait...';
        $("form").append('<div class="loader-wrapper"><div class="loader" style="vertical-align: middle; position: absolute; top: 50%; left: 50%; margin: -50px 0px 0px -50px;"></div></div>');
        $("form").find('.loader-wrapper').fadeIn('fast');
    }
    //To Handle Special Characters in Form Fields
    if ($("form").hasClass("js-handleSpecialChars-form")) {
        var fields = document.querySelectorAll('form input[type=text], textarea');
        if (!!fields && fields.length > 0) {
            for (let fieldsToHandle of fields) {
                let fieldValue = fieldsToHandle.value;
                fieldsToHandle.value = fieldValue.replace(/["]/g, '&quot;').replace(/[']/g, '&apos;');
            }
        }
    }
};

function updateUrl(filterName) {
    let currentUrl = window.location.href;
    let updatedUrl;

    if (filterName === "All") {
        // Remove Filters and page parameters entirely for the All tab
        updatedUrl = currentUrl.replace(/(&?Filters=.*?)(&|$)/, "")
            .replace(/(&|\?)page=\d+/i, "")
            .replace(/page=\d+/i, "")
            .replace(/[\?&]$/, "");
    } else {
        let newFilterValue = `[{"Name":"${filterName}","Values":["true"]}]`;

        if (currentUrl.includes("Filters")) {
            // Replace Filters value with the new one
            updatedUrl = currentUrl.replace(/Filters=.*?(&|$)/, `Filters=${encodeURIComponent(newFilterValue)}&`);
        } else {
            // Append Filters to the URL
            updatedUrl = currentUrl + `&Filters=${encodeURIComponent(newFilterValue)}`;
        }

        // Remove existing page parameter and rebuild page=0
        updatedUrl = updatedUrl.replace(/(&|\?)page=\d+/i, "");
        updatedUrl += "&page=0"; // Append page=0 to ensure reset
    }

    // Ensure the URL is clean (no trailing ? or &)
    updatedUrl = updatedUrl.replace(/[\?&]$/, "");

    // Update browser URL
    window.history.pushState(null, null, updatedUrl);

    console.log("Updated URL:", updatedUrl);

    // Reload the page to apply the changes
    location.reload();
}
// Function to handle clicks on tabs
$(".quick-filter-tab-button").on("click", function () {
    let tabName = $(this).data("filter");
    $('.quick-filter-tab-button').removeClass('active');

    // Save the selected tab to sessionStorage
    sessionStorage.setItem("activeTab", tabName);

    // Call updateUrl with the corresponding filter or "All"
    updateUrl(tabName);

    // Add active class to the clicked tab
    $(this).addClass('active');

});
$(document).ready(function () {
    let url = window.location.href;

    // Check if Filters parameter exists in the URL
    if (url.includes("Filters")) {
        let activeTab = sessionStorage.getItem("activeTab");
        if (activeTab) {
            // Restore the tab stored in sessionStorage
            $(`.quick-filter-tab-button[data-filter='${activeTab}']`).addClass('active');
        } else {
            // Default to "All" if no activeTab is stored
            $(".quick-filter-tab-button[data-filter='All']").addClass('active');
        }
    } else {
        // If Filters parameter doesn't exist, select "All"
        $(".quick-filter-tab-button[data-filter='All']").addClass('active');
        sessionStorage.setItem("activeTab", "All"); // Update sessionStorage
    }

       
});

export { filter };